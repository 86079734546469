<template>
  <!-- 任务列表详情 -->
  <div class="mission-details grey-bg">
    <!--任务列表详情头部开始-->
    <div class="mission-head">
      <div class="mission-title" >完成任务奖励<span v-if="taskDetail.rule">{{
          $handle.tranNumber(taskDetail.rule.detail.amount, 3)
        }}{{ taskDetail.rule.type == 'experience' ? '经验' : '积分' }}</span>
         <span v-if="taskDetail.integral"> {{
            $handle.tranNumber(taskDetail.integral.detail.amount, 3)
          }}{{ taskDetail.integral.type == 'credit' ? '积分' : '经验' }}</span>
      </div>
      <div class="mission-tip white-space">完成须知：{{ taskDetail.notice }}</div>
    </div>
    <!--任务列表详情头部结束-->
    <div class="team-info vertical-center" v-if="is_show">
      <img class="team-info-img" :src="taskDetail.cover_picture ? taskDetail.cover_picture : $confStatic.taskImg"
           alt="">
      <div class="team-info-con">
        <div class="team-title white-space">{{ taskDetail.name }}</div>
        <div class="team-complete">已有<span class="team-complete-span">{{
            $handle.tranNumber(taskDetail.count, 3)
          }}</span>人完成
        </div>
      </div>
    </div>
    <!--任务步骤开始-->
    <div class="step">
      <div class="step-con">
        <div class="task-step-one">
          <div class="step-title">1.操作步骤</div>
          <!--                    <div class="step-tip">点击去完成完成任务</div>-->
          <div class="step-one-img rich-text" v-html="taskDetail.step">

          </div>
        </div>
        <div>

        </div>
        <div class="task-step-one">
          <div class="step-title">2.资料上传</div>
          <div class="step-tip"></div>
          <div class="uploader">
            <van-uploader v-model="fileList" :max-count="1" :max-size="10240 * 1024" @oversize="onOversize"
                          :deletable="taskDetail.status == -1 || taskDetail.status == 5" :before-delete="deleteImg"/>
            <!--                        <van-uploader v-model="fileList" :max-count="1" :max-size="10240 * 1024" @oversize="onOversize"  :deletable="taskDetail.status == -1 || taskDetail.status == 5" :after-read="uploaderFun" :before-delete="deleteImg" />-->
          </div>
        </div>
        <div class="task-step-one task-step-three vertical-center space-between">
          <div>
            <div class="step-title">3.完成任务</div>
            <div class="step-tip" v-if="taskDetail.status == 0">资料提交后，审核结果需要1-3个工作日</div>
          </div>
          <div v-if=" taskDetail.status == -1 || taskDetail.status == 5 ">
            <div class="to-finish vertical-center" v-if="taskDetail.type == 2 && taskDetail.url" @click="linkFun">
              <div class="to-finish-text">
                去完成
              </div>
              <img class="to-finish-img" src="~assets/images/task/to-icon.png"/>
            </div>
            <div v-if="taskDetail.type == 1 && taskDetail.app_id && taskDetail.url" v-html="applet_html"></div>
          </div>

        </div>
        <div class="audit-status">
          <div class="status" v-if="taskDetail.status == 1">审核通过</div>
          <div class="status failed" v-else-if="taskDetail.status == -1">审核未通过</div>
          <div class="status failed" v-else-if="taskDetail.status == 0">待审核</div>
        </div>
      </div>
      <!--上传按钮开始-->
      <div class="upload-btn" v-if="!is_loding">
        <div class="task-upload" :class="{'forbid-bg' : fileList[0] == undefined }" @click="taskUpload"
             v-if="(taskDetail.status == -1 || taskDetail.status == 5) && btn_status">
          <!--                <div class="task-upload" :class="{'forbid-bg' : content == ''}" @click="taskUpload" v-if=" taskDetail.status == -1 || taskDetail.status == 5 ">-->
          确认上传
        </div>
      </div>
      <!--上传按钮结束-->
    </div>
    <!--任务步骤结束-->
    <div>
      <!--驳回申请提示开始-->
      <van-overlay :show="isReject" z-index="9999">
        <div class="overlay-confirmation-audit-con align-center">
          <div class="overlay-confirmation-audit">
            <div class="overlay-confirmation-head align-center">
              <div class="overlay-confirmation-text">提示</div>
            </div>
            <div class="overlay-confirmation-body vertical-center">
              <div class="overlay-confirmation-tip-text">您提交的任务已驳回，驳回原因：
                <span class="overlay-confirmation-tip-span">{{ taskDetail.reason }}</span></div>
              <div class="overlay-btn">
                <div class="cancel" @click="closeEject">取消</div>
                <div class="confirm" @click="closeEject">确定</div>
              </div>
            </div>
          </div>
        </div>
      </van-overlay>
      <!--驳回申请提示结束-->
    </div>
  </div>

</template>

<script>
import cache from "@/utils/cache";
import _ from "lodash";

export default {
  name: "show",
  data() {
    return {
      id: '',   // 任务id
      fileList: [],
      taskDetail: {},
      link_status: false,
      content: '',
      applet_html: '',
      isReject: false,
      uploader_status: true,
      uploaderTimeout: null,
      is_show: false,
      is_loding: false,
      goDay: 0,
      package_id: 0,
      is_showBtn: false,
      btn_status: true
    }
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.package_id = this.$route.params.package_id ? this.$route.params.package_id : 0
      this.getShow();
      this.goDay = new Date().getDay()
    }
    if (this.userInfo.is_fresher == 1){
      this.$router.replace({path: '/'})
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$util.domMinHeight()
    })
  },
  watch: {
    $router() {
      window.location.reload()
    }
  },
  methods: {
    //  获取任务详情
    getShow() {
      let url = this.$api.Tasks + '/' + this.id + '?package_id=' + this.package_id;
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          let info = res.data.data;
          if (info.type == 1 && info.app_id && info.url) {
            this.applet_html = `<wx-open-launch-weapp id="launch-btn" username="${info.app_id}" path="${info.url}">
                                        <template>
                                            <style>
                                                .vertical-center{
                                                    display: flex;
                                                    display: -webkit-flex;
                                                    align-items: center;
                                                    -webkit-align-items: center;
                                                }
                                                .to-finish-text{
                                                    font-size: 14px;
                                                    font-weight: 500;
                                                    color: #FF6B29;
                                                    line-height: 20px;
                                                    margin-right: 4px;
                                                    background-color: #FFFFFF;
                                                    border: none;
                                                    padding: 0!important;
                                                }
                                                .to-finish-img{
                                                    width: 14px;
                                                    height: 14px;
                                                }
                                            </style>
                                            <div class="to-finish vertical-center">
                                                <button class="to-finish-text">去完成</button>
                                                <img class="to-finish-img" src="https://static.helixlife.cn/edu/home/images/to-icon.png"/>
                                            </div>
                                        </template>
                                 </wx-open-launch-weapp>`
            this.$wxShare.wxShare(info.name, info.name, info.cover_picture, this.userInfo.invite_code)
          }
          if (info.content) {
            this.fileList = [{url: info.content[0]}];
            this.content = info.content[0]
          }
          this.taskDetail = info
          this.is_show = true
          let tip = localStorage.getItem('isTaskTip')
          if (this.taskDetail.status === -1) {
            if (tip == 'false') {
              this.isReject = true
              localStorage.setItem('isTaskTip', true)
            }
          }
          if (res.data.data.can_do === false) {
            setTimeout(() => {
              this.$router.replace({path: '/task'})
            }, 1500)
          }
        }
      })
    },
    // 图片上传
    uploaderFun(file) {
      if (this.uploaderTimeout !== null) {
        clearTimeout(this.uploaderTimeout)
      }
      file.status = 'uploading';
      file.message = '上传中...';
      this.uploaderTimeout = setTimeout(() => {
        file.status = 'failed';
        file.message = '上传失败';
      }, 60000)
      this.$http.uploader(this.$api.Uploads, file.file, true).then(res => {
        if (res.data.success) {
          clearTimeout(this.uploaderTimeout)
          this.fileList = [{
            url: res.data.data.url,
          }];
          this.content = res.data.data.url;
        } else {
          clearTimeout(this.uploaderTimeout);
          file.status = 'failed';
          file.message = '上传失败';
          this.$toast(res.data.message)
        }
      }).catch(error => {
        clearTimeout(this.uploaderTimeout);
        file.status = 'failed';
        file.message = '上传失败';
      })
    },
    //  图片限制大小
    onOversize() {
      this.$toast('文件大小不能超过 10MB')
    },
    //  删除图片
    deleteImg() {
      this.content = '';
      this.fileList = [];
    },
    //  图片删除

    //  完成任务
    taskUpload: _.debounce(function () {
      if (this.fileList[0] == undefined) {
        return
      }
      this.btn_status = false
      // 判断是否在同一周
      // var oneDayTime = 1000 * 60 * 60 * 24;
      // var old_count = parseInt(+this.goDay / oneDayTime);
      // var now_other = parseInt(+new Date().getDay() / oneDayTime);
      // let is_week = parseInt((old_count + 4) / 7) == parseInt((now_other + 4) / 7);
      // if (!is_week) {
      //     this.$toast('该任务已过期')
      //     return
      // }
      if (this.is_loding) {
        return
      }
      this.is_loding = true
      if (this.uploaderTimeout !== null) {
        clearTimeout(this.uploaderTimeout)
      }
      this.fileList[0].status = 'uploading';
      this.fileList[0].message = '上传中...';
      this.uploaderTimeout = setTimeout(() => {
        this.fileList[0].status = 'failed';
        this.fileList[0].message = '上传失败';
        this.is_loding = false
        this.btn_status = true
      }, 60000)
      if (this.content === this.fileList[0].url) {
        this.finishTask()
      } else {
        var param = new FormData()
        param.append('file', this.fileList[0].file)
        param.append('package_id', this.package_id)
        param.append('task_id', this.id)
        this.$http.post(this.$api.Uploads, param, true).then(res => {
          if (res.data.success) {
            clearTimeout(this.uploaderTimeout)
            this.fileList = [{
              url: res.data.data.url,
            }];
            this.content = res.data.data.url;
            this.finishTask()
          } else {
            this.btn_status = true
            this.is_loding = false
            clearTimeout(this.uploaderTimeout);
            this.fileList[0].status = 'failed';
            this.fileList[0].message = '上传失败';
            this.$toast(res.data.message)
            setTimeout(() => {
              if (res.data.message === '该任务已过期') {
                this.$router.replace({path: '/task'})
              }
            }, 1500)
          }
        }).catch(error => {
          this.btn_status = true
          this.is_loding = false
          clearTimeout(this.uploaderTimeout);
          this.fileList[0].status = 'failed';
          this.fileList[0].message = '上传失败';
        })
      }

      // if(this.content == ''){
      //   return false;
      // }
      if (!this.uploader_status) {
        return false
      }

    }, 500),
    // 上传任务
    finishTask() {
      this.uploader_status = false
      let data = {
        task_id: this.taskDetail.id,
        action: 'upload',
        content: this.content,
        package_id: this.package_id
      }
      let url = this.$api.UserTasks
      this.$http.post(url, data, true).then(res => {
        clearTimeout(this.uploaderTimeout)
        if (res.data.success) {
          this.$toast('上传成功');
          this.getShow();
          setTimeout(() => {
            localStorage.setItem('isTaskTip', false)
          }, 300)
        } else {
          this.btn_status = true
          this.$toast(res.data.message);
          // this.is_showBtn = true
          this.fileList[0].status = 'failed';
          this.fileList[0].message = '上传失败';
          setTimeout(() => {
            if (res.data.message === "当前任务已过期" || res.data.message === '该任务已过期') {
              this.$router.replace({path: '/task'})
            }
          }, 1500)
        }
        this.uploader_status = true
        this.is_loding = false
      }).catch(error => {
        clearTimeout(this.uploaderTimeout)
        this.btn_status = true
        this.is_loding = false
        this.$toast(error.response.data.message);
        if (error.response.status == 422) {
          setTimeout(() => {
            this.$router.replace({path: '/task'})
          }, 1500)
        }
      })
    },
    //  点击去完成
    linkFun() {
      if (this.taskDetail.type == 2 && this.taskDetail.url) {
        window.location.href = this.taskDetail.url
      }
    },
    // 关闭驳回弹窗
    closeEject() {
      localStorage.setItem('isTaskTip', true)
      this.isReject = false
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/task/show.scss";

$r: 750/16/1rem;
//驳回申请提示开始
.overlay-confirmation-audit-con {
  height: 100%;

  .overlay-confirmation-audit {
    width: 600/$r;
    background: #FFFFFF;
    border-radius: 20/$r;

    .overlay-confirmation-head {
      width: 600/$r;
      height: 127/$r;
      box-sizing: border-box;
      background: #FF9E73;
      border-radius: 20/$r 20/$r 0/$r 0/$r;

      .overlay-confirmation-text {
        font-size: 32/$r;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
    }

    .overlay-confirmation-body {
      flex-direction: column;

      .overlay-confirmation-tip-text {
        width: 478/$r;
        margin: 64/$r 0 99/$r;
        font-size: 28/$r;
        font-weight: 500;
        color: #333333;
        line-height: 36/$r;

        span {
          color: #FF6B29;
        }
      }

      .overlay-btn {
        display: flex;
        margin-bottom: 68/$r;
        font-size: 28/$r;
        font-family: Source Han Sans CN;
        font-weight: 300;
        line-height: 74/$r;
        color: #999999;

        .cancel {
          width: 240/$r;
          height: 74/$r;
          margin-right: 42/$r;
          background: #FFFFFF;
          border: 1px solid #B5B5B5;
          border-radius: 37/$r;
          text-align: center;
        }

        .confirm {
          width: 240/$r;
          height: 74/$r;
          background: #FF6B29;
          border-radius: 37/$r;
          text-align: center;
          color: #FFFFFF;
        }
      }
    }
  }
}

//驳回申请提示结束
</style>
<style>

.step-one-img ul{
  list-style-type:none!important;
}
.step-one-img ol{
  list-style-type:none!important;
}

</style>